var as = as || {};

as.options = {
    jspath: '/js',
    jsErrorHandler: '/ArkStuff/jsError'
};
as.init = function (options) {
    as.options = $.extend(as.options, options);
    as.loadLibs();    
    as.sys.init();
    as.ark.init();
};

as.loadLibs = function () {
  
    as.sys.loadLib({
        jslink: as.options.jspath + "/fancy/source/jquery.fancybox.pack.js",
        csslink: as.options.jspath + "/fancy/source/jquery.fancybox.css",
        conditionObject: $.fancybox,
        callback: function () { },
        isCacheable: true
    });
    as.sys.loadLib({
        jslink: as.options.jspath + "/jquery.bootstrap-growl.min.js",
        csslink: "",
        conditionObject: $.bootstrapGrowl,
        callback: function () { },
        isCacheable: true
    });
    as.sys.loadLib({
        jslink: "/js/bootstrap-editable/js/bootstrap-editable.js",
        csslink: "/js/bootstrap-editable/css/bootstrap-editable.css",
        conditionObject: $.editable,
        callback: function () { as.loadedEditable = true },
        isCacheable: true
    });
        
    as.sys.loadLib({
        jslink: "/js/bootstrap-datepicker/js/bootstrap-datepicker.min.js",
        csslink: "/js/bootstrap-datepicker/css/bootstrap-datepicker.min.css",
        conditionObject: $.fn.datepicker,
        callback: function () { },
        isCacheable: true
    });
}

as.lang = function (code, defValue) {
    var res = defValue;
    var el = as.resources && as.resources[code];
    if (el) res = el;
    return res;
}

as.loadedEditable = false;

$(function () {
    as.init({});
});

   