var as = as || {};

as.notification = {
    options: {
        ajaxURLFormat: "/Notification/{0}",  // get, save           
        cont: null,
        alertType: ""
    },
    init: function (options) {
        as.notification.options = $.extend(as.notification.options, options);

        $(as.notification.options.cont).delegate('.as-notification-showAlertTypes>a', 'click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var code = $(this).attr('data-code');          
            as.notification.show(code);
        });
        if (as.notification.options.cont.length > 0) {
            as.notification.show();
        }
        $(as.notification.options.cont).delegate('.as-notification-process', 'click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            as.notification.process($(this));
        });
   
        $(as.notification.options.cont).delegate('.as-notification-additional', 'click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            as.notification.showAdditional($(this));
        });
        
        $(as.notification.options.cont).delegate('.as-notification-answer', 'click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            as.notification.answer($(this));
        });
       
        $(as.notification.options.cont).delegate('li', 'click', function (e) {
            if (!$(e.target).is("a")) e.stopPropagation();
          
        });

        if ($('.as-notification-count').length > 0) {
            as.notification.showUnprocessedCount($('.as-notification-count'));

        }
        console.log($('.as-notification-count').length);

    },
    show: function (alertType) {
        var params = { alertType: alertType || "" };        
        as.notification.options.alertType = params.alertType;
        as.sys.ajaxSend(as.notification.options.ajaxURLFormat.format("ShowNotifications"), params, function (data) {
            if (typeof (data) != "object") data = eval('(' + data + ')');
            if (data.result) {
                var s = [];
                s.push('<li class="as-notification-showAlertTypes"><a href="#" data-code="" class="btn btn-default btn-xs" title="Show all types"><i class="fa fa-th-list fa-fw"></i></a><a href="#" data-code="info" class="btn btn-info btn-xs" title="Show only information notification`s"><i class="fa fa-info fa-fw"></i></a><a href="#" data-code="warning" class="btn btn-warning btn-xs" title="Show only warning notification`s"><i class="fa fa-warning fa-fw"></i></a><a href="#" data-code="danger" class="btn btn-danger btn-xs" title="Show only especially important notification`s"><i class="fa fa-bomb fa-fw "></i></a></li>');
                s.push('<li class="as-notification-item-cont"><a class="text-center" href="/notification" target="_blank"><strong>All notifications &nbsp;</strong><i class="fa fa-angle-double-right"></i></a></li>');
                if (data.items.length > 0) {
                    $.each(data.items, function (i, item) {                        
                        var dt = as.makeup.timeSinceFormat(new Date(item.created), new Date(data.now));
                        var alertTypeClass = 'alert alert-info';
                        if (item.alertType == "warning") alertTypeClass += "alert alert-warning";
                        if (item.alertType == "danger") alertTypeClass += "alert alert-danger";

                        var additionalBtn = "";
                        if (item.hasAdditional) additionalBtn = '<a class="btn btn-warning btn-xs as-notification-additional"  href="#"  title="Show additional information"><i class="fa fa-info"></i></a>';
                      
                        var answerBtn = "";
                        if (item.canAnswer) answerBtn = '<a class="btn btn-success btn-xs as-notification-answer"  href="#"  title="Answer"><i class="fa fa-pencil"></i></a>';
                      
                        var fromName = item.fromName;
                        if (!fromName) fromName = item.from;
                        var url = item.url != "" ? item.url : "#";
                        s.push('<li class="as-notification-item-cont" data-itemID="'+item.id+'"><div class="small '+alertTypeClass+'">' +
                               '<span class="pull-right text-muted small">&nbsp;' + dt + '&nbsp;&nbsp;</span>' +
                             '<span class="as-notification-name">'+ item.text + '</span>' +
                             '<br /><a class="btn btn-default btn-xs as-notification-process"  href="#"  title="Hide notification"><i class="fa fa-thumbs-o-up"></i></a>' +
                              additionalBtn +
                              answerBtn +
                             '&nbsp;<a href="' + url + '" class="as-notification-from" title="' + item.from + '">' + fromName + '</a>' +
                            '</div></li>');
                    });
                } else {
                    s.push('<li><a href="#"><div><i class="fa fa-search fa-fw"></i> No elements</div></a></li>');
                }
                as.notification.options.cont.html(s.join(""));                
            } else {
                as.sys.bootstrapAlert(data.msg || "Some errors ocured during loading data!", { type: 'danger' });
            }
        });
    },
    process: function (btn) {
        ar = [];
        ar.push(parseInt($(btn).closest('li').attr('data-itemid')));
        var params = { ar: ar };       
        as.sys.ajaxSend(as.notification.options.ajaxURLFormat.format("ProcessNotification"), params, function (data) {
            if (typeof (data) != "object") data = eval('(' + data + ')');
            if (data.result) {               
                $(btn).closest('li').hide(50, function () { $(this).remove(); });
                as.notification.show(as.notification.options.alertType);                
                var count = parseInt($('.dropdown.open .as-notification-count>.alert-info').text());
                if (count) {
                    count--;
                    $('.as-notification-count>.alert-info').text(count)
                }
            } else {
                as.sys.bootstrapAlert(data.msg || "Some errors ocured during loading data!", { type: 'danger' });
            }
        });
    },
    showAdditional: function (btn) {
        var li = $(btn).closest('li');
        var itemID = li.attr('data-itemID');
        var params = { itemID: itemID };
        as.sys.ajaxSend(as.notification.options.ajaxURLFormat.format("getAdditional"), params, function (data) {
            if (typeof (data) != "object") data = eval('(' + data + ')');
            if (data.result) {
                var s = [];
                s.push("<div class='as-notification-modal-cont' data-itemID='"+itemID+"'>");
                s.push(data.additional);
                s.push("</div>");
                var header = $(".as-notification-name", li).text().replace("Go to the order", "");
                as.sys.showDialog2(header, s.join(""), "Processed", function () {
                    $(".as-notification-cont>li[data-itemID=" + itemID + "]").find('.as-notification-process').trigger('click');
                    setTimeout(function () {
                        as.sys.closeDialog2();
                    }, 200);
                });
            } else {
                as.sys.bootstrapAlert(data.msg || "Some errors ocured during loading data!", { type: 'danger' });
            }
        });
    },

    answer: function (btn) {
        var li = $(btn).closest('li');
        var itemID = li.attr('data-itemID');        
        
        var s = "";
        s += "<div class='as-notification-modal-cont' data-itemID='" + itemID + "'>";
        s += "<p class='help-block'>Whom <strong>" + $(".as-notification-from", li).text() + "</strong></p>";
        s += "<p class='help-block'>" + $(".as-notification-name", li).html() + "</p>";
        s+="<textarea maxlength='250' class='form-control as-notification-answerText' rows='4' ></textarea>";
        s += "</div>";
        as.sys.showDialog("Write massege", s, "Send", function () {
            var answer = $('.as-notification-answerText').val();
            if (!answer) {
                $('.as-notification-answerText').focus();
                return;
            }
            var params = {itemID: itemID, answer: answer}
            as.sys.ajaxSend(as.notification.options.ajaxURLFormat.format("answer"), params, function (data) {
                if (typeof (data) != "object") data = eval('(' + data + ')');
                if (data.result) {
                    $(".as-notification-cont>li[data-itemID=" + itemID + "]").find('.as-notification-process').trigger('click');
                    setTimeout(function () {
                        as.sys.bootstrapAlert("Massege sended!", { type: 'success' });
                        as.sys.closeDialog();
                    }, 200);
                } else {
                    as.sys.bootstrapAlert(data.msg || "Some errors ocured during loading data!", { type: 'danger' });
                }
            });
           
        });
    },
    showUnprocessedCount: function (label) {
        var params = {  };
        as.sys.ajaxSend(as.notification.options.ajaxURLFormat.format("getUnprocessedCount"), params, function (data) {
            if (typeof (data) != "object") data = eval('(' + data + ')');
            if (data.result) {
                if (data.count == 0) {
                    $(label).html("<span class='alert alert-info'>" + data.count + "</span>");
                    $(label).html(data.count);
                } else {
                    $(label).html("<span class='alert alert-info'><b>" + data.count + "</b></span>");
                }
              
            } else {
                as.sys.bootstrapAlert(data.msg || "Some errors ocured during loading data!", { type: 'danger' });
            }
        });
    },

   

};