var as = as || {};

as.sys = {   
    init: function () {
        as.sys.setTypeProcessor();
        as.sys.registerStringFunction();
        $('body').append("<div class='modal fade' id='asModal'><div class='modal-dialog' ><div class='modal-content'>"+
                "<div class='modal-header'><button type='button' class='close' data-dismiss='modal' aria-hidden='true'>&times;</button><h4 class='modal-title'>Менеджер файлов</h4></div>"+
                "<div class='modal-body'><p></p></div>"+
            "<div class='modal-footer text-right'><button type='button' class='btn btn-link btn-close' data-dismiss='modal'>" + as.resources.sys_closeModal+"</button><button type='button' class='btn btn-primary'>Ок</button></div>"+
            "</div></div ></div >");
    },
    isDebug: false,
    openProgressBar: function () {
        if($('.as-progCont').length==0){
            $('body').append("<div class='as-progCont'></div>");
        }       
        $('.as-progCont').html('<div class="as-overlay">' + as.resources.sysConfirm + '</div>');
    },
    closeProgressBar: function () {
        $('.as-overlay').fadeOut('slow', function () {
            $(this).remove();
        });
    },
    setSortable: function (cont, params) {
        $(cont).sortable(params);
    },
    showPopover: function (params) {
        // align, btn, urlm ajaxParams, callback, titleCallback
        params = $.extend({ align: 'left' }, params);
        var btn = params.btn;
        var jsonFilter = JSON.stringify({});
        as.sys.ajaxSend(params.url, params.ajaxParams, function (data) {
            data = eval('(' + data + ')');
            var text = params.callback ? params.callback(data) : data.text;
            var title = params.titleCallback ? params.titleCallback(data) : params.btn.html();
            if (btn.next().is(".popover")) {
                btn.next().find('.popover-content').html(text);
            } else {
                btn.popover({
                    content: text,
                    title: '<span class="text-info"><strong>' + title + '</strong></span>' +
                        '<button type="button" id="close" class="close" >&times;</button>',
                    html: true, placement: params.align
                }).popover('show');
            }
        });
    },
    showMessage: function (text) {
        $.fancybox(text);
    },
    closeMessage: function () {
        $.fancybox.close();
    },
    showNotice: function (params) {
        $.gritter.add({
            // (string | mandatory) the heading of the notification
            title: params.title || '',
            // (string | mandatory) the text inside the notification
            text: params.text || '',
            // (string | optional) the image to display on the left
            image: params.image || '',
            // (bool | optional) if you want it to fade out on its own or just sit there
            sticky: params.sticky || false,
            // (int | optional) the time you want it to be alive for before fading out (milliseconds)
            time: params.time || 8000,
            // (string | optional) the class name you want to apply directly to the notification for custom styling
            class_name: 'my-class',
            // (function | optional) function called before it opens
            before_open: function () {
            },
            // (function | optional) function called after it opens
            after_open: function (e) {
            },
            // (function | optional) function called before it closes
            before_close: function (e, manual_close) {
                // the manual_close param determined if they closed it by clicking the "x"                    
            },
            // (function | optional) function called after it closes
            after_close: function () {
            }
        });
    },
    showSimpleNotice: function (text, title) {
        as.sys.showNotice({ title: title, text: text });
    },
    showDialog: function (title, html, buttonText, callback, isBig, callbackAfterLoadingWindow, callbackClose, window, options) {
        if (!window) window = $("#asModal");
        if ($('.modal:visible').length > 0) {
            window.css('zIndex', parseInt($('.modal:visible').css('zIndex')) + 5);
        }


        $('.modal-title', window).html(title);
        $('.modal-body', window).html(html);
        var btn = $('.modal-footer .btn-primary', window);
        var btnClose1 = $('.modal-header button.close', window);
        var btnClose2 = $('.modal-footer button.btn-close', window);
        btn.html(buttonText || 'OK');
        if (callback) btn.off().click(callback);
        if (callbackClose) btnClose1.off().click(callbackClose);
        if (callbackClose) btnClose2.off().click(callbackClose);
        if (isBig) {
            window.addClass('asBigModal');
        } else {
            window.removeClass('asBigModal');
        }

        

        var backdrop = true;
        if (options) {
            if (options.closeText) {
                btnClose2.text(options.closeText);
            }
            if (options.hasOwnProperty('backdrop')) {
                backdrop = options.backdrop;
            }
            if (options.hasOwnProperty('subtitle')) {
                $('.modal-sub-title', window).text(options.subtitle);
            } else {
                $('.modal-sub-title', window).empty();
            }
        }

        $(btnClose2).remove();

        window.modal({ show: true, backdrop: backdrop });

        // Подключаем Ctrl + S к главной кнопке
        if (!as.sys.initForDialogHotKey) {
            as.sys.initForDialogHotKey = true;
            $('body').keydown(function (e) {
                if ($('#as-modal:visible').length > 0 && e.ctrlKey && e.which == 83) {
                    e.preventDefault();
                    $('#as-modal .btn-primary').trigger('click');
                }
            });

        }

        if (callbackAfterLoadingWindow) callbackAfterLoadingWindow();
        
    },
    closeDialog: function (window) {
        if (!window) window = $('#asModal');
        window.modal('hide');
    },
    showDialog2: function (title, html, buttonText, callback, callbackClose) {
        var content = "<div class='fancy-cont'>";
        if (title) content += "<h4>" + title + "</h4>";
        if (html) content += "<div>" + html + "</div><br />";

        if (buttonText) {
            content += "<a href='#' class='btn btn-primary pull-right'>" + buttonText + "<a/>";
        }
        content += "<a href='#' class='btn pull-right fancy-close'>Закрыть<a/>";
        content += "</div>";

        const version = +$.fancybox.version.split('.')[0];

        if (version == 2) {
            $.fancybox({
                content: content,
                modal: true
            });
        } else if (version == 3) {
            $.fancybox.open(content, { modal: true })
        } else {
            console.error('Unsupported version of fancybox. Version 2.X.X or 3.X.X only supported');
        }

        if (callback) {
            setTimeout(function () {
                $('.fancy-cont .btn-primary').click(callback);
            }, 200);
        }
        if (callbackClose) {
            setTimeout(function () {
                $('.fancy-cont .fancy-close').click(callbackClose);
            }, 200);
        }
    },
    closeDialog2: function () {
        $.fancybox.close();
    },
    //  function handleCallback(page_index, pagination_container) { 
    //var url =   '/page='+ (parseInt(page_index)+1); 
    //if(page_index==0) url = 'ddddd'
    //window.location = url;
    //return false;
    //}
    setPaginator: function (cont, total, pagesize, page, handleCallback) {
        $(cont).pagination(total, {
            items_per_page: pagesize,
            callback: handleCallback,
            prev_text: 'Prev',
            next_text: 'Next',
            num_display_entries: 10,
            num_edge_entries: 1,
            current_page: page - 1
        });
        var pagingcontainerwidth = 252 + 20 * total / pagesize;
        $(cont).css('width', '' + pagingcontainerwidth + 'px');
    },
    setTypeProcessor: function () {

        $(document).delegate(".asInt", "keypress", function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                return false;
            }
        });
        $(document).delegate(".asFloat", "keypress", function (e) {
            if (e.which != 8 && e.which != 0 && ((e.which < 48 && e.which != 44) || e.which > 57)) {
                return false;
            }
        });
        $(document).delegate(".asPhone", "keyup", function (e) {
            var t = $(this).val();
            $(this).attr("value", t);
        });
        $(document).delegate(".asPhone", "focus", function (e) {
            $(this).inputmask("+99999999999[99]",
                {
                    greedy: false, placeholder: "", groupmarker: { start: "<", end: ">" }, showMaskOnHover: false, showMaskOnFocus: false, clearMaskOnLostFocus: false
            });
        });
        $(document).delegate(".asPhone", "blur", function (e) {
            var element = $(this);
            var isCorrect = true;
            $(element).inputmask({
                mask: function () {
                    var value = $(element).val();
                    switch (value.length) {
                        case 12:
                            return ["+9 (999) 999-9999"];
                        case 13:
                            return ["+99 (999) 999-9999"];
                        case 14:
                            return ["+999 (999) 999-9999"];
                        default:
                            as.sys.bootstrapAlert("You enter wrong phone number.", { type: "warning" });
                            isCorrect = false;
                            return null;
                    }
                }
                
                }, { greedy: false, placeholder: "", groupmarker: { start: "<", end: ">" }, showMaskOnHover: false, showMaskOnFocus: false, clearMaskOnLostFocus: false }
            );
            if (element.hasClass('as-editable')) {
                if (isCorrect) {
                    element.change();
                }
            }
        });

        $(document).delegate(".asDatepicker", "click", function () {
            $(this).datepicker({
                showOn: 'focus', format: "yyyy-mm-dd", yearRange: '1950:+5', changeYear: true,
            
            }).focus();
        });

        $(document).delegate('.as-html', 'asHtmlEvent', function () {            
                tinyMCE.init({
                    valid_elements: '*[*]',
                    mode: "specific_textareas",
                    editor_selector: "as-html",
                    encoding: "xml",
                    theme: "advanced",
                    height: 400,
                    plugins: "advimage,advlink,emotions,iespell,inlinepopups,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,xhtmlxtras,template,aspnetbrowser",
                    theme_advanced_buttons1: "bold,italic,underline,strikethrough,|,justifyleft,justifycenter,justifyright,justifyfull,styleselect,formatselect,fontselect,fontsizeselect",
                    theme_advanced_buttons2: "cut,copy,paste,pastetext,pasteword,|,search,replace,|,bullist,numlist,|,outdent,indent,blockquote,|,undo,redo,|,link,unlink,anchor,image,cleanup,help,code",
                    theme_advanced_buttons3: "aspnetbrowser,|,insertdate,inserttime,preview,|,forecolor,backcolor",
                    theme_advanced_buttons4: "",
                    theme_advanced_toolbar_location: "top",
                    theme_advanced_toolbar_align: "left",
                    theme_advanced_statusbar_location: "bottom",
                    theme_advanced_resizing: true,
                    relative_urls: false,
                    remove_script_host: true,
                    convert_urls: false,
                    setup: function (ed) {
                        /* ed.onKeyUp.add(function(ed) {
                             console.log('dd');
                             ed.save();
                         }); */
                    }
                });               
        });

        $(document).delegate('.as-html-lite', 'asHtmlLiteEvent', function () {
            tinyMCE.init({
                valid_elements: '*[*]',
                mode: "specific_textareas",
                editor_selector: "as-html-lite",
                encoding: "xml",
                theme: "advanced",
                height: "400",
                width: "400",
                plugins: "advimage,advlink,emotions,iespell,inlinepopups,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,xhtmlxtras,template,aspnetbrowser",
                theme_advanced_buttons1: "bold,italic,underline,|,formatselect",
                theme_advanced_buttons2: "",
                theme_advanced_buttons3: "",
                theme_advanced_buttons4: "",
                theme_advanced_toolbar_location: "top",
                theme_advanced_toolbar_align: "left",
                theme_advanced_statusbar_location: "bottom",
                theme_advanced_resizing: true,
                relative_urls: false,
                remove_script_host: true,
                convert_urls: false,
                setup: function (ed) {
                    /* ed.onKeyUp.add(function(ed) {
                         console.log('dd');
                         ed.save();
                     }); */
                }
            });
        });

        $(document).delegate('.as-daterange', 'mouseover', function () {
            var range = {};
            range[as.resources.sys_setTypeProcessor_range1] = [moment(), moment()];
            range[as.resources.sys_setTypeProcessor_range2] = [moment().subtract(6, 'days'), moment()];
            range[as.resources.sys_setTypeProcessor_range3] = [moment().subtract(29, 'days'), moment()];
            range[as.resources.sys_setTypeProcessor_range4] = [moment().subtract(3, 'month').startOf('month'), moment().endOf('month')];
            range[as.resources.sys_setTypeProcessor_range5] = [moment().subtract(6, 'month').startOf('month'), moment().endOf('month')];
            range[as.resources.sys_setTypeProcessor_range6] = [moment().subtract(12, 'month').startOf('month'), moment().endOf('month')];

            $(this).daterangepicker({
                ranges:range,
                format: 'DD/MM/YYYY'
            }, function (start, end, label) {
                console.log(start.toISOString(), end.toISOString(), label);
            });
        });        
    },
    loadLib : function (options) {
        // jslink, csslink, conditionObject, callback, isCacheable
        if (!options.conditionObject) {
            if (options.csslink) {
                $('head').append('<link href="' + options.csslink + '" rel="stylesheet" />');
            }
            $.ajax({
                dataType: "script",
                cache: options.isCacheable ? options.isCacheable : false,
                url: options.jslink,
                success: function () {
                    if (options.callback) options.callback();
                }
            });
        } else {
            if (options.callback) options.callback();
        }
    },
    ajaxSend: function (url, data, callback, noProgressBar, btn) {
        var params = data;
        var txt = "";
        if (btn) {
            btn.addClass('disabled');
            btn.attr('disabled', 'disabled');
        }
        if (!noProgressBar) as.sys.openProgressBar();
        var xhr = $.ajax({
            type: 'POST',
            url: url,
            cache: false,
            traditional: true,
            dataType: "json",
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify( params),
            success: function (data, status) {
                var response = data;
                if (data.d != undefined) response = data.d;
                if (typeof (response) != "object") response = eval('(' + response + ')');
                if (callback) callback(response);
            },
            complete: function () {
                if (!noProgressBar) as.sys.closeProgressBar();
                if (btn) {
                    btn.removeClass('disabled');
                    btn.removeAttr('disabled');
                }
            },
            error: function(jqXHR, textStatus, errorThrown){
                if (as.sys.logJSError && jqXHR.status > 0) {
                    as.sys.logJSError(url + JSON.stringify(params) + ": " + jqXHR.status + " " + textStatus + ", " + errorThrown);
                }
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            }
        });
        return xhr;
    },

    ajax: function (options) {  // url, data, onSuccess, onError, noProgressBar, btn, method, logError, cache

        if (options.logError == undefined) options.logError = true;
        var params = options.data;
        var txt = "";
        if (options.btn) {
            options.btn.addClass('disabled');
            options.btn.attr('disabled', 'disabled');
        }
        if (!options.noProgressBar) as.sys.openProgressBar();
        $.ajax({
            type: (options.method || 'POST'),
            url: options.url,
            cache: options.cache || false,
            traditional: true,
            dataType: "json",
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(params),
            success: function (data, status) {
                var response = data;
                if (data.d != undefined) response = data.d;
                if (typeof (response) != "object") response = eval('(' + response + ')');
                if (options.onSuccess) options.onSuccess(response);
            },
            complete: function () {
                if (!options.noProgressBar) as.sys.closeProgressBar();
                if (options.btn) {
                    btn.removeClass('disabled');
                    btn.removeAttr('disabled');
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (as.sys.logJSError && options.logError) {
                    var err = this.url + ' ' + JSON.stringify(params) + ' ' + textStatus;
                    if (errorThrown) {
                        err += ', ' + errorThrown;
                    }
                    as.sys.logJSError(err);
                }
                if (options.onError) options.onError(jqXHR, textStatus, errorThrown);
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            }
        });
    },

    logJSError: function (str) {
        console.log(as.sys.jsErrorPeriod);
        as.sys.jsErrorPeriod = as.sys.jsErrorPeriod || 500;
        as.sys.jsErrorPeriod  = as.sys.jsErrorPeriod  * 4;
        setTimeout(function(){
            var username =  $('.ark-username').val() || null;
            var path = location.pathname || document.documentURI || null;
            as.sys.ajaxSend(as.options.jsErrorHandler, { s: str, user: username, path: path }, function () { });
        }, as.sys.jsErrorPeriod);
    },
    // сохранить значение в куки
    setCookie: function (name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    // загрузить значение с куки
    getCookie: function (c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                var c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    },
    registerStringFunction: function () {
        if (typeof String.prototype.startsWith != 'function') {
            String.prototype.startsWith = function (str) {
                return this.substring(0, str.length) === str;
            }
        };
        if (typeof String.prototype.endsWith != 'function') {
            String.prototype.endsWith = function (str) {
                return this.substring(this.length - str.length, this.length) === str;
            }
        };
        if (typeof String.prototype.format != 'function') {
            String.prototype.format = function () {
                // заменяем странные скобки
                var regexp1 = /%7B/g;
                var text = this.replace(regexp1, "\{");
                var regexp2 = /%7D/g;
                var text1 = text.replace(regexp2, "\}");

                var pattern = /\{\d+\}/g;
                var args = arguments;

                var str1 = text1.replace(pattern, function (capture) { return args[capture.match(/\d+/)]; });
                return str1;
            }
        };
    },
    bootstrapAlert: function (str, options) {
        /*
        {
        type: 'error',
        align: 'center',
        width: 'auto',
        allow_dismiss: false,
        stackup_spacing: 30
        }


        ele: 'body', // which element to append to
        type: 'info', // (null, 'info', 'danger', 'success')
        offset: {from: 'top', amount: 20}, // 'top', or 'bottom'
        align: 'right', // ('left', 'right', or 'center')
        width: 250, // (integer, or 'auto')
        delay: 4000, // Time while the message will be displayed. It's not equivalent to the *demo* timeOut!
        allow_dismiss: true, // If true then will display a cross to close the popup.
        stackup_spacing: 10 // spacing between consecutively stacked growls.
        */

        //        {
        //        ele: "body",
        //    type: "info",
        //    allow_dismiss: true,
        //    position: {
        //        from: "top",
        //        align: "right"
        //    },
        //    offset: 20,
        //    spacing: 10,
        //    z_index: 1031,
        //    fade_in: 400,
        //    delay: 5000,
        //    pause_on_mouseover: false,
        //    onGrowlShow: null,
        //    onGrowlShown: null,
        //    onGrowlClose: null,
        //    onGrowlClosed: null,
        //    template: {
        //        icon_type: 'class',
        //        container: '<div class="col-xs-10 col-sm-10 col-md-3 alert">',
        //        dismiss: '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>',
        //        title: '<strong>',
        //        title_divider: '',
        //        message: ''
        //    }


        return $.bootstrapGrowl ? $.bootstrapGrowl(str, options) : alert(str);
    },
  
    alert: function (str, options) {
        if (options.cont) {
            var alertElement = $("<div class='alert'></div>"),
                alertCont = options.cont.append(alertElement);

            switch (options.type) {
                case 'success':
                    alertElement.addClass('alert-success')
                    break
                case 'warning':
                    alertElement.addClass('alert-warning')
                    break
                case 'error':
                case 'danger':
                    alertElement.addClass('alert-danger')
                    break
                default:
                    alertElement.addClass('alert-info')
            }

            alertElement.addClass('alert-dismissible as-sys-alert fade in');
            alertElement.attr('role', 'alert');
            alertElement.html('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + str);

            var timeout = options.timeout && options.timeout > 0 ? options.timeout : 5000;
            setTimeout(function () {
                alertElement.alert('close');
            }, timeout);
            return alertCont;
        }

        else {
            return as.sys.bootstrapAlert(str, options);
        }
    }, 
    
    trace: function (text) {        
        var dt = new Date().getTime();
        var res = 0;
        if (as.sys.lastTraceDate != undefined) {
            res = dt - as.sys.lastTraceDate;
        }
        as.sys.lastTraceDate = dt;
        console && console.log(res + "  " + text);
    },
    store: {
        get: function (key, isSession) {
            var storage = isSession ? sessionStorage : localStorage;
            return storage.getItem(key);            
        },
        set: function (key, data, isSession) {
            var storage = isSession ? sessionStorage : localStorage;
            storage.setItem(key, data);
        },
        remove: function (key, isSession) {
            var storage = isSession ? sessionStorage : localStorage;
            storage.removeItem(key);            
        },
    },

    isEmail: function (value) {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    },

    isPhone: function (value) {
        return /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})/
            .test(value);
        //return /^[\+]{0,1}(\d{10,13}|[\(][\+]{0,1}\d{2,}[\13)]*\d{5,13}|\d{2,6}[\-]{1}\d{2,13}[\-]*\d{3,13})$/.test(value);
    },

    createGantt: function (selector, tasks, options = {}) {
        const defaultOptions = {
            language: "ru",
            show_days_of_week: true,
        };

        const ganttOptions = { ...defaultOptions, ...options };

        var gantt = new Gantt(selector, tasks, ganttOptions);

        return gantt;
    },

    test() {
        $('head title').text('текст наа русском');
    }

};